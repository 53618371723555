import React from 'react';
import styled from '@emotion/styled';
import { H4 } from 'components/Atom/Typo';

const Component = styled.div`
  display: flex;
  margin-bottom: 8px;

  ${H4} {
    font-size: 14px;
    height: 20px;
    line-height: 1.79;

    em {
      color: ${(props) => props.theme.colors.danger5};
      font-style: normal;
    }
  }
`;

interface InputLabelProps {
  title: string;
  isRequired: boolean;
}

const InputLabel = ({ title, isRequired }: InputLabelProps) => {
  return (
    <Component>
      <H4>
        {title}
        {isRequired ? <em>(필수*)</em> : ''}
      </H4>
    </Component>
  );
};

export default InputLabel;
